import './archivPage.scss';
import ArchivBig from '../../components/ArchivBig/ArchivBig';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

import text from '../../content/content.json';
import { useDkck } from '../../context/Context';
import ArchivBigOpened from '../../components/ArchivBig/ArchivBigOpened';

function ArchivPage() {
  const { program } = useDkck();

  const [wideScreen, setWideScreen] = useState(false);
  const [archivOpened, setArchivOpened] = useState({
    activeArchiv: '0',
    archiv: program,
  });

  useEffect(() => {
    if (window.innerWidth >= 1100) {
      setWideScreen(true);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth >= 1100) {
      setWideScreen(true);
    } else {
      setWideScreen(false);
    }
  };
  const toggleActive = (i) => {
    if (archivOpened.activeArchiv === i && !wideScreen) {
      setArchivOpened({ ...archivOpened, activeArchiv: undefined });
    } else {
      setArchivOpened({ ...archivOpened, activeArchiv: i });
    }
  };

  return (
    <motion.div
      className="archivPage page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
    >
      <Header showLPLinks={true} />
      <div className="archivHero">
        <h1>Program</h1>
        <h2>{text.archiv.title}</h2>
      </div>
      <main className="main">
        <div className="main_left archivAll">
          <h3>{text.archiv.title}</h3>
          {program?.loadComplete &&
            program.data
              ?.filter((item) => item.time && new Date(item.time) < new Date())
              ?.sort((a, b) => {
                const dateASplit = a.time.split(/\.\D|[-]|[T]/);
                const dateA = new Date(
                  dateASplit[2],
                  dateASplit[1] - 1,
                  dateASplit[0],
                );
                const dateBSplit = b.time.split(/\.\D|[-]|[T]/);
                const dateB = new Date(
                  dateBSplit[2],
                  dateBSplit[1] - 1,
                  dateBSplit[0],
                );
                return dateB - dateA;
              })
              ?.map((archiv, i) => (
                <ArchivBig
                  key={i}
                  speaker={archiv.speaker}
                  archivTitle={archiv.eventName}
                  speakerInfo={archiv.speakerInfo}
                  archivSpeakerImage={archiv.speakerImage}
                  archivDetails={archiv.desc}
                  archivDate={archiv.time}
                  archivAlignment={i % 2 !== 0 ? 'flex-end' : 'flex-start'}
                  archivColor={i % 2 === 0 ? '#8a407f' : '#ec0062'}
                  id={i}
                  archivGallery={archiv.gallery}
                  {...{ archivOpened, setArchivOpened, wideScreen }}
                  toggleActive={toggleActive}
                />
              ))}
        </div>
        {program?.loadComplete &&
          program.data
            .filter((item) => item.time && new Date(item.time) < new Date())
            .sort((a, b) => {
              const dateASplit = a.time.split(/\.\D|[-]|[T]/);
              const dateA = new Date(
                dateASplit[2],
                dateASplit[1] - 1,
                dateASplit[0],
              );
              const dateBSplit = b.time.split(/\.\D|[-]|[T]/);
              const dateB = new Date(
                dateBSplit[2],
                dateBSplit[1] - 1,
                dateBSplit[0],
              );
              return dateB - dateA;
            })
            .map((archiv, i) => (
              <ArchivBigOpened
                key={i}
                speaker={archiv.speaker}
                archivTitle={archiv.eventName}
                speakerInfo={archiv.speakerInfo}
                archivSpeakerImage={archiv.speakerImage}
                archivDetails={archiv.desc}
                archivDate={archiv.time}
                id={i}
                archivColor={i % 2 === 0 ? '#8a407f' : '#ec0062'}
                archivGallery={archiv.gallery}
                {...{ archivOpened, setArchivOpened, wideScreen }}
                toggleActive={toggleActive}
                archivText={archiv.archivText}
              />
            ))}
      </main>
      <Footer />
    </motion.div>
  );
}

export default ArchivPage;
