import React from 'react';
import { useNavigate } from 'react-router-dom';
import arrow from '../../images/arrow.png';
import './subPageButton.scss';

function SubPageButton(props) {
  let navigate = useNavigate();

  return (
    <div className="subpageButton" onClick={() => navigate(`/${props.pushTo}`)}>
      <h2>{props.title}</h2>
      <img src={arrow} alt="right arrow" />
    </div>
  );
}

export default SubPageButton;
