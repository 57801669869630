import '@brainhubeu/react-carousel/lib/style.css';
import './archiv.scss';
import Carousel, { Dots, arrowsPlugin } from '@brainhubeu/react-carousel';
import left from '../../images/left.svg';
import right from '../../images/right.svg';
import leftDis from '../../images/left_disabled.svg';
import rightDis from '../../images/right_disabled.svg';
import ArchiveEvent from './ArchiveEvent/ArchiveEvent';
import React, { useEffect, useState } from 'react';
import SubPageButton from '../SubPageButton/SubPageButton';
import text from '../../content/content.json';
import { useDkck } from '../../context/Context';

const Archiv = () => {
  const [value, setValue] = useState(0);
  const [slides, setSlides] = useState([]);

  const { program } = useDkck();

  useEffect(() => {
    if (!program.loadComplete) return;
    setSlides([
      ...program?.data
        .filter((item) => item.time && new Date(item.time) < new Date())
        .sort((a, b) => {
          const dateASplit = a.time.split(/\.\D|[-]|[T]/);
          const dateA = new Date(
            dateASplit[2],
            dateASplit[1] - 1,
            dateASplit[0],
          );
          const dateBSplit = b.time.split(/\.\D|[-]|[T]/);
          const dateB = new Date(
            dateBSplit[2],
            dateBSplit[1] - 1,
            dateBSplit[0],
          );
          return dateA - dateB;
        })
        .slice(0, 4)
        .map((i) => (
          <ArchiveEvent
            speaker={i.speaker}
            title={i.eventName}
            speakerImage={i.speakerImage}
          />
        )),
    ]);
    // eslint-disable-next-line
  }, [program?.data]);

  const onChangeHandler = (value) => {
    setValue(value);
  };
  return (
    <section id="archiv" className="archiv_section section">
      <SubPageButton pushTo="archiv" title={text.archiv.title} />
      <Carousel
        value={value}
        slides={slides}
        onChange={onChangeHandler}
        plugins={[
          'infinite',
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={left}
                    alt="šipka doleva"
                  />
                </button>
              ),
              arrowLeftDisabled: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={leftDis}
                    alt="šedá šipka doleva"
                  />{' '}
                </button>
              ),
              arrowRight: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={right}
                    alt="šipka doprava"
                  />{' '}
                </button>
              ),
              arrowRightDisabled: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={rightDis}
                    alt="šedá šipka doprava"
                  />{' '}
                </button>
              ),
              addArrowClickHandler: true,
            },
          },
        ]}
      />
      <Dots value={value} onChange={onChangeHandler} number={slides.length} />
    </section>
  );
};

export default Archiv;
