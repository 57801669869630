import React, { useEffect, createContext, useContext, useReducer } from 'react';
import contentJSON from '../content/content.json';
import storiesJSON from '../content/stories.json';

const DOMAIN = 'https://sbkadmin.cz/dkck';
export const ENDPOINT_DEF = DOMAIN + '/v1';
export const ENDPOINT_PROGRAM = DOMAIN + '/v1/public/program';

const reducerInitialState = {
  data: {},
  isFetching: false,
  hasError: false,
  loadComplete: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isFetching: true,
        hasError: false,
        loadComplete: false,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        loadComplete: true,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        hasError: true,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const DkckContext = createContext();

export const DkckProvider = ({ children }) => {
  const content = contentJSON;
  const stories = storiesJSON;
  const [program, dispatchProgram] = useReducer(reducer, reducerInitialState);

  useEffect(() => {

    // FETCH PROGRAM

    dispatchProgram({
      type: 'FETCH_INIT',
    });
    fetch(ENDPOINT_PROGRAM, {
      headers: {},
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        dispatchProgram({
          type: 'FETCH_SUCCESS',
          payload: resJson,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatchProgram({
          type: 'FETCH_FAILURE',
        });
      });
  }, []);
  return (
    <DkckContext.Provider
      value={{
        content,
        stories,
        ENDPOINT_DEF,
        program,
        dispatchProgram,
        ENDPOINT_PROGRAM,
      }}
    >
      {children}
    </DkckContext.Provider>
  );
};

export const useDkck = () => useContext(DkckContext);
