import React from 'react';

function StoryBigOpened({
  storyColor,
  name,
  storyTitle,
  storyImg,
  story,
  story2,
  storyMotto,
  id,
  title,
  www,
  wwwName,
  storyOpened,

}) {
  return (
    <div
      id={id}
      className={id === storyOpened.activeStory ? 'storyOpened' : 'storyClosed'}
    >
      <div
        className="storyOpened_top"
        style={{ backgroundColor: `${storyColor}` }}
      >
        {name}
        <h5>{title}</h5>
      </div>
      <div className="storyOpened_content">
        <div className="storyOpened_contentTop">
          <div className="storyOpened_contentTopText">
            {' '}
            <h5 className="storyTitle">{storyTitle}</h5>{' '}
            <h6 className="story_motto">{storyMotto}</h6>{' '}
            {www && (
              <a href={www} target="_blank" rel="noreferrer">
                {wwwName}
              </a>
            )}
          </div>
          <div
            className="bigStory_img"
            style={{ backgroundImage: `url(${storyImg})` }}
          />{' '}
        </div>
        <div>
          <p>{story}</p>
          <p className="no_margin">{story2}</p>
        </div>
      </div>
    </div>
  );
}

export default StoryBigOpened;
