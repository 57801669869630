import React from 'react';
import Header from '../../components/Header/Header';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import Form from '../../components/Form/Form';

import './registrationForm.scss';

function RegistrationForm() {
  return (
    <motion.div
      className="registrationform_page page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
    >
      <Helmet>
        <title>Registrace na akci</title>
      </Helmet>
      <Header showLPLinks={true} />
      <div className="registrationHero">
        <h1>Program</h1>
        <h2>Registrační formulář</h2>
      </div>
      <section className="registrationForm">
        <Form />
      </section>
      <Footer />
    </motion.div>
  );
}

export default RegistrationForm;
