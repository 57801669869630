import './archiveEvent.scss';

import React from 'react';

function ArchiveEvent(props) {
  return (
    <>
      <div className="archivEvent">
        <div
          className="archivEvent_img"
          style={{ backgroundImage: `url(${props.speakerImage})` }}
        ></div>
        <div className="archivEvent_right">
          <h5>{props.speaker}</h5>
          <p>{props.title}</p>
        </div>
      </div>
    </>
  );
}

export default ArchiveEvent;
