import React from 'react';
import phone from '../../images/phone.svg';
import email from '../../images/email.svg';
import text from '../../content/content.json';
import gdpr from '../../images/Informacni_povinnost_spravce_osobnich_udaju.pdf';
import './footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="address">
        <h5>{text.contact.name}</h5>
        <p>{text.contact.address}</p>
      </div>
      <div className="contactDetails">
        <div className="contact">
          <img className="icon" src={phone} alt="ikona telefonu" />
          <p>{text.contact.phone}</p>
        </div>
        <div className="contact">
          <img className="icon" src={email} alt="ikona obálky" />
          <a
            className="contact_Email"
            href="mailto:info@damskyklubck.cz"
            target="_blank"
            rel="noreferrer"
          >
            {text.contact.email}
          </a>
        </div>
      </div>
      <div className="icGdpr">
        <p>{text.contact.ic}</p>
        <a href={gdpr}>
          <p>{text.contact.GDPR}</p>
        </a>
      </div>
    </footer>
  );
}

export default Footer;
