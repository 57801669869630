import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import text from '../../content/content.json';
import jitka from '../../images/jitka_resized.webp';
import olga from '../../images/olga_resized.webp';
import phone from '../../images/phoneContact.svg';
import email from '../../images/emailContact.svg';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import './contactPage.scss';
import ImageLink from "../../components/ImageLink/ImageLink";

function ContactPage() {
  return (
    <motion.div
      className="contactPage"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
    >
      <Helmet>
        <title>Kontakty - Dámský klub</title>
      </Helmet>

      <Header showLPLinks={true} />
      <div className="contactHero">
        <h1>Kontakt</h1>{' '}
      </div>
      <main className="contactMain">
        <div className="contactRight section">
          <h2 className="contactTitle">{text.contact.name}</h2>
          <div className="contactDetail">
            <p className="contactDetail_bold">Adresa:</p>
            <p className="contactDetail_detail">{text.contact.address}</p>{' '}
          </div>
          <div className="contactDetail">
            <p className="contactDetail_bold">Telefon:</p>
            <p className="contactDetail_detail">{text.contact.phone}</p>{' '}
          </div>
          <div className="contactDetail">
            <p className="contactDetail_bold">Email:</p>
            <p className="contactDetail_detail">{text.contact.email}</p>{' '}
          </div>
          <div className="contactDetail">
            <p className="contactDetail_bold">IČ:</p>
            <p className="contactDetail_detail">{text.contact.ic}</p>{' '}
          </div>
          <div className="contactDetail">
            <p className="contactDetail_bold">Výroční zprávy:</p>
            <p className="contactDetail_detail">
              <ImageLink url={"/docs/Damsky-klub-Cesky-Krumlov_zprava-2023.pdf"} icon="📄">
                Zpráva o činnosti 2023
              </ImageLink>
            </p>
          </div>
        </div>

        <div className="contactLeft">
          <h3>Zakladatelky</h3>
          <div className="contactOne">
            <div className="pink">
              <h4>{text.contact.jitka.name}</h4>
            </div>
            <div
              className="photo"
              style={{ backgroundImage: `url(${jitka})` }}
            ></div>

            <div className="phoneEmail">
              <img className="icon" src={phone} alt="ikona telefonu" />
              <p>{text.contact.jitka.phone}</p>
            </div>
            <div className="phoneEmail">
              <img className="icon" src={email} alt="ikona obálky" />
              <p>{text.contact.jitka.email}</p>
            </div>
          </div>
          <div className="contactOne contactOne_right">
            <div className="purple">
              <h4>{text.contact.olga.name}</h4>
            </div>
            <div
              className="photo"
              style={{ backgroundImage: `url(${olga})`, alignSelf: 'flex-end' }}
            ></div>
            <div className="phoneEmail" style={{ alignSelf: 'flex-end' }}>
              <img className="icon" src={phone} alt="ikona telefonu" />
              <p>{text.contact.olga.phone}</p>
            </div>
            <div className="phoneEmail" style={{ alignSelf: 'flex-end' }}>
              <img className="icon" src={email} alt="ikona obálky" />
              <p>{text.contact.olga.email}</p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </motion.div>
  );
}

export default ContactPage;
