import React from 'react';
import text from '../../content/content.json';
import quotes from '../../images/quotes.svg';
import Carousel, { arrowsPlugin, Dots } from '@brainhubeu/react-carousel';
import left from '../../images/left.svg';
import right from '../../images/right.svg';
import leftDis from '../../images/left_disabled.svg';
import rightDis from '../../images/right_disabled.svg';
import '@brainhubeu/react-carousel/lib/style.css';
import './referenceQ.scss';

class ReferenceQuote extends React.Component {
  constructor() {
    super();
    this.state = {
      value: 0,
      slides: [
        <>
          {' '}
          <div className="oneQuote">
            <p className="referenceQuote">{text.referenceQuotes.quote1}</p>
            <h5 className="referencePerson">{text.referenceQuotes.name1}</h5>
            <h5 className="referencePersonTag">{text.referenceQuotes.tag1}</h5>
          </div>
        </>,
        <>
          <div className="oneQuote">
            <p className="referenceQuote">{text.referenceQuotes.quote2}</p>
            <h5 className="referencePerson">{text.referenceQuotes.name2}</h5>
            <h5 className="referencePersonTag">{text.referenceQuotes.tag2}</h5>
          </div>
        </>,
        <>
          <div className="oneQuote">
            <p className="referenceQuote">{text.referenceQuotes.quote3}</p>
            <h5 className="referencePerson">{text.referenceQuotes.name3}</h5>
            <h5 className="referencePersonTag">{text.referenceQuotes.tag3}</h5>
          </div>
        </>,
      ],
    };
    this.onchange = this.onchange.bind(this);
  }
  onchange(value) {
    this.setState({ value });
  }

  render() {
    return (
      <section className="referenceQuote_section section">
        <img className="quotes_icon" src={quotes} alt="uvozovky" />
        <Carousel
          value={this.state.value}
          slides={this.state.slides}
          onChange={this.onchange}
          plugins={[
            'infinite',
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <button style={{ border: 'none' }}>
                    <img
                      style={{
                        marginRight: '11px',
                        width: '24px',
                        height: '24px',
                      }}
                      src={left}
                      alt="šipka doleva"
                    />
                  </button>
                ),
                arrowLeftDisabled: (
                  <button style={{ border: 'none' }}>
                    <img
                      style={{
                        marginRight: '11px',
                        width: '24px',
                        height: '24px',
                      }}
                      src={leftDis}
                      alt="šedá šipka doleva"
                    />{' '}
                  </button>
                ),
                arrowRight: (
                  <button style={{ border: 'none' }}>
                    <img
                      style={{
                        marginLeft: '11px',
                        width: '24px',
                        height: '24px',
                      }}
                      src={right}
                      alt="šipka doprava"
                    />{' '}
                  </button>
                ),
                arrowRightDisabled: (
                  <button style={{ border: 'none' }}>
                    <img
                      style={{
                        marginLeft: '11px',
                        width: '24px',
                        height: '24px',
                      }}
                      src={rightDis}
                      alt="šedá šipka doprava"
                    />{' '}
                  </button>
                ),
                addArrowClickHandler: true,
              },
            },
          ]}
        ></Carousel>
        <Dots
          value={this.state.value}
          onChange={this.onchange}
          number={this.state.slides.length}
        />
      </section>
    );
  }
}

export default ReferenceQuote;
