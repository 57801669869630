import React from 'react';

function SendButton(props) {
  const color = typeof props.color !== 'undefined' ? props.color : 'purpleBtn';

  return (
    <button
      type="submit"
      style={{ textAlign: 'center' }}
      className={`registrationBtn ${color}`}
      onClick={() => props.onClick}
    >
      Odeslat
    </button>
  );
}

export default SendButton;
