import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import logo from '../../images/logo.svg';
import './header.scss';

function Header(props) {
  return (
    <header className="header">
      <div className="logoDiv">
        <Link to="/">
          <img className="logoHeader" src={logo} alt="logo Dámského klubu" />
        </Link>
      </div>
      <Navbar />
    </header>
  );
}

export default Header;
