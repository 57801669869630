import React from 'react';
import text from '../../content/content.json';
import './statement.scss';

function Statement() {
  return (
    <section className="statement_section">
      <h3>{text.statement.title}</h3>
      <p className="statementP_left statementP">{text.statement.p1}</p>
      <p className="statementP_right statementP">{text.statement.p2}</p>
      <p className="statementP_left statementP">{text.statement.p3}</p>
      <p className="statementP_right statementP">{text.statement.p4}</p>
      <p className="statementP_left statementP">{text.statement.p5}</p>
      <p className="statementP_right statementP">{text.statement.p6}</p>
      <p className="statementP_left statementP">{text.statement.p7}</p>
      <p className="statementP_right statementP">{text.statement.p8}</p>
    </section>
  );
}

export default Statement;
