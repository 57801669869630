import React from 'react';
import arrowDown from '../../images/arrowDown.svg';
import './archivBig.scss';

function ArchivBig({
  speaker,
  archivTitle,
  archivAlignment,
  archivColor,
  id,
  archivOpened,
  toggleActive,
}) {
  return (
    <div className="archiv">
      <div
        className="archivSpeaker_div"
        onClick={() => toggleActive(id.toString())}
        style={{
          backgroundColor: `${archivColor}`,
          alignSelf: `${archivAlignment}`,
        }}
      >
        <div className="archivSpeaker_div2">
          <div>
            <h4>{speaker}</h4>
            <h5>{archivTitle}</h5>
          </div>
          <img
            src={arrowDown}
            alt="arrow icon"
            className={
              id === parseInt(archivOpened.activeArchiv)
                ? 'arrowUp'
                : 'arrowDown'
            }
          />
        </div>
      </div>
    </div>
  );
}

export default ArchivBig;
