import React, { useEffect, useState } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './programPage.scss';
import ProgramMain from '../../components/ProgramMain/ProgramMain';
import CurrentEvent from '../../components/CurrentEvent/CurrentEvent';
import Archiv from '../../components/Archiv/Archiv';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useDkck } from '../../context/Context';
import { useParams } from 'react-router';

function ProgramPage() {
  const { program } = useDkck();
  const { preselectedId } = useParams();

  const archiveProgram =
    program?.loadComplete &&
    program.data
      .filter((item) => item.time && new Date(item.time) < new Date())
      .sort((a, b) => {
        const dateASplit = a.time.split(/\.\D|[-]|[T]/);
        const dateA = new Date(dateASplit[0], dateASplit[1] - 1, dateASplit[2]);
        const dateBSplit = b.time.split(/\.\D|[-]|[T]/);
        const dateB = new Date(dateBSplit[0], dateBSplit[1] - 1, dateBSplit[2]);
        return dateA - dateB;
      });

  const [wideScreen, setWideScreen] = useState(false);
  const [eventOpened, setEventOpened] = useState({
    id: undefined,
    color: undefined,
    btnColor: undefined,
  });

  useEffect(() => {
    if (window.innerWidth >= 1100) {
      setWideScreen(true);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth >= 1100) {
      setWideScreen(true);
    } else {
      setWideScreen(false);
    }
  };

  return (
    <motion.div
      className="programPage page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
    >
      <Helmet>
        <title>Program Dámského klubu</title>
        <meta
          name="description"
          content="Přehled všech našich událostí - Vaše příležitost pro setkání se zajímavými a inspirativními lidmi."
        />{' '}
      </Helmet>
      <Header showLPLinks={true} />
      <div className="heroSection_program section">
        <h1>Program</h1>
      </div>
      <main className="main">
        <div className="main_right">
          <ProgramMain
            slice="6"
            {...{
              eventOpened,
              preselectedId,
              program,
              setEventOpened,
              wideScreen,
            }}
          />
        </div>
        <div className="main_left">
          {wideScreen && eventOpened.id &&<CurrentEvent {...{ eventOpened, program }} />}
          {!!archiveProgram.length && <Archiv {...{ archiveProgram }} />}
        </div>
      </main>
      <Footer />
    </motion.div>
  );
}

export default ProgramPage;
