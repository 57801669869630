import React from 'react';
import Button from '../Button/Button';
import arrowDown from '../../images/arrowDown.svg';
import { useNavigate } from 'react-router';
import location from '../../images/location.svg';
import './event.scss';

function Event({
  btnColor,
  color,
  dateAlign,
  dateOrder,
  event,
  eventAlignment,
}) {
  let navigate = useNavigate();


  return (
    <div className="event" style={{ alignSelf: `${eventAlignment}` }}>
      <div
        onClick={() => navigate(`/program/${event.id}`)}
        className="event_speaker"
        style={{ backgroundColor: `${color}` }}
      >
        <h4>{event.speaker}</h4>
        <img src={arrowDown} alt="arrow icon" />
      </div>
      <div className="event_details">
        <div
          className="event_info"
          style={{
            order: `${dateOrder}`,
            textAlign: `${dateAlign}`,
          }}
        >
          <p className="event_title">{event.eventName}</p>
          <p className="event_date">
            {event.time
              ? new Date(event.time).toLocaleString('cs', {
                  timeZone: "Europe/Prague",
                  dateStyle: 'medium',
                  timeStyle: 'short',
                })
              : 'Datum bude upřesněno'}
          </p>
          {event.location && (
            <a
              className="event_location"
              href={event.location[1]}
              target="_blank"
              rel="noreferrer"
            >
              <img src={location} alt="ikona špendlíku na mapě" />
              <p>{event.location[0]}</p>
            </a>
          )}
        </div>
        <div className="event_buttons">
          <Button color={btnColor} id={event.id} />
          {event.fullCapacity === true ? (
            <span className="soldOut">
              Vyprodáno
              <br />
              <span className="soldOut_waitingList">(pořadník)</span>
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Event;
