import React from 'react';
import './supporterOne.scss';

function SupporterOne(props) {
  return (
    <a
      className="supporter_a"
      href={props.href}
      target="_blank"
      rel="noreferrer"
    >
      <div className="supporter">
        {props.supporterLogo && (
          <div className="supporter_wrapper">
            <img
              className="supporter_logo"
              src={props.supporterLogo}
              alt="logo podporovatele"
            />
          </div>
        )}
        <h4 className="supporter_name">{props.supporter}</h4>
        <p className="supporter_text">{props.supporterText}</p>
      </div>{' '}
    </a>
  );
}

export default SupporterOne;
