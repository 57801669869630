import React from 'react';
import './photoDetails.scss';
import { motion } from 'framer-motion';

function PhotoDetails({ setSelectedImg, selectedImg, archivGallery }) {
  const handleClick = (e) => {
    if (e.target.classList.contains('backdrop')) {
      setSelectedImg(null);
    }
  };

  return (
    <motion.div
      className="backdrop"
      onClick={handleClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <img src={archivGallery[selectedImg]} alt="enlarged pic" />
    </motion.div>
  );
}

export default PhotoDetails;
