import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import location from '../../images/location.svg';

import './currentEvent.scss';

function CurrentEvent({ eventOpened, program }) {
  const [event, setEvent] = useState();

  useEffect(() => {
    if (!program.data.length) return;
    setEvent(program.data.find((item) => item.id === parseInt(eventOpened.id)));
  }, [eventOpened, program.data]);

  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <section className="currentEvent_section section">
      {event && (
        <div
          id={event.id}
          className={
            parseInt(eventOpened.id) === event.id
              ? 'eventOpened'
              : 'eventClosed'
          }
        >
          <div
            className="eventOpened_top"
            style={{ backgroundColor: `${eventOpened.color}` }}
          >
            {event.speaker}
          </div>
          <div className="eventOpened_content">
            <h5 className="eventTitle">{event.eventName}</h5>
            <div className="soldOutdiv">
              <div className="event_locDate">
                <h4 className="event_dateTime">
                  Datum:&nbsp;
                  {event.time
                    ? new Date(event.time).toLocaleString('cs', {
                        timeZone: "Europe/Prague",
                        dateStyle: 'medium',
                        timeStyle: 'short',
                      })
                    : 'Datum bude upřesněno'}
                </h4>
                {event.location && (
                  <a
                    className="event_location"
                    href={event.location[1]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Místo:&nbsp;
                    <img src={location} alt="ikona špendlíku na mapě" />
                    <p>{event.location[0]}</p>
                  </a>
                )}
              </div>{' '}
              {event.fullCapacity === true ? (
                <span className="soldOut">
                  Vyprodáno
                  <br />
                  <span className="soldOut_waitingList">(pořadník)</span>
                </span>
              ) : null}
            </div>

            <div className="event_moreInfo">
              <div>
                <h5>Detaily akce</h5>
                <p dangerouslySetInnerHTML={createMarkup(event.desc)} />
              </div>
              <div className="eventSpeakerDetails">
                <div className="eventSpeakerDetails_text">
                  <h5>S kým se potkáte</h5>
                  <p>{event.speakerInfo}</p>
                </div>
                <div className="eventSpeakerImage">
                  <img src={event.speakerImage} alt="" />
                </div>
              </div>
            </div>
            <Button color={eventOpened.btnColor} id={event.id} />
          </div>
        </div>
      )}
    </section>
  );
}

export default CurrentEvent;
