import React from 'react';
import { motion } from 'framer-motion';
import './gallery.scss';

function Gallery({ archivGallery, setSelectedImg }) {
  return (
    <>
      {' '}
      <div className="archiv_gallery">
        {archivGallery &&
          archivGallery.map((img, selectedImg) => (
            <motion.div
              className="bigArchiv_img"
              key={archivGallery[selectedImg]}
              layout
              whileHover={{ opacity: 1 }}
              s
              onClick={() => setSelectedImg(selectedImg)}
            >
              <motion.img
                src={img}
                alt="obrázek z galerie"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1 }}
              />
            </motion.div>
          ))}
      </div>
    </>
  );
}

export default Gallery;
