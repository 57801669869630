import React, { useEffect, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import StoryBig from '../../components/StoryBig/StoryBig';
import text from '../../content/content.json';
import stories from '../../content/stories.json';
import jitka from '../../images/jitka_resized.webp';
import bara from '../../images/bara_resized.webp';
import olga from '../../images/olga_resized.webp';
import { motion } from 'framer-motion';
import '../../sass/_colors.scss';
import './storiesPage.scss';
import { useParams } from 'react-router';
import StoryBigOpened from '../../components/StoryBig/StoryBigOpened';

function StoriesPage() {
  const photos = {
    jitka: { src: jitka },
    bara: { src: bara },
    olga: { src: olga },
  };
  const [storyOpened, setStoryOpened] = useState({
    activeStory: undefined,
    stories: stories,
  });

  const { preselectedStory } = useParams();

  useEffect(() => {
    setStoryOpened((storyOpened) => ({
      ...storyOpened,
      activeStory: preselectedStory ? preselectedStory : 'jitka',
    }));
  }, [preselectedStory]);

  const [wideScreen, setWideScreen] = useState(false);

  useEffect(() => {
    if (window.innerWidth >= 1100) {
      setWideScreen(true);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    if (window.innerWidth >= 1100) {
      setWideScreen(true);
    } else {
      setWideScreen(false);
    }
  };

  const toggleActive = (i) => {
    if (storyOpened.activeStory === i && !wideScreen) {
      setStoryOpened({ ...storyOpened, activeStory: undefined });
    } else {
      setStoryOpened({ ...storyOpened, activeStory: i });
    }
  };

  return (
    <motion.div
      className="storiesPage page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
    >
      <Header showLPLinks={true} />
      <div className="storiesHero">
        <h1>O nás</h1>
        <h2>{text.stories.title}</h2>
      </div>
      <main className="main storiesMain">
        <div className="storiesAll">
          <h3>Naše ženy</h3>
          {Object.keys(stories).map((story, i) => (
            <StoryBig
              storyColor={i % 2 === 0 ? '#8a407f' : '#ec0062'}
              name={stories[story].name}
              storyTitle={stories[story].storyTitle}
              storyImg={photos[stories[story].key].src}
              story={stories[story].story}
              story2={stories[story].story2}
              alignment={i % 2 !== 0 ? 'flex-end' : 'flex-start'}
              storyMotto={stories[story].perex}
              key={i}
              id={story}
              title={stories[story].title}
              {...{ storyOpened, setStoryOpened, wideScreen }}
              www={stories[story].www}
              wwwName={stories[story].wwwName}
              toggleActive={toggleActive}
            />
          ))}
        </div>
        <div className="main-right">
          {Object.keys(stories).map((story, i) => (
            <StoryBigOpened
              storyColor={i % 2 === 0 ? '#8a407f' : '#ec0062'}
              name={stories[story].name}
              storyTitle={stories[story].storyTitle}
              storyImg={photos[stories[story].key].src}
              story={stories[story].story}
              story2={stories[story].story2}
              alignment={i % 2 !== 0 ? 'flex-end' : 'flex-start'}
              storyMotto={stories[story].perex}
              key={i}
              id={story}
              title={stories[story].title}
              {...{ storyOpened, setStoryOpened, wideScreen }}
              www={stories[story].www}
              wwwName={stories[story].wwwName}
              toggleActive={toggleActive}
            />
          ))}
        </div>
      </main>
      <Footer />
    </motion.div>
  );
}

export default StoriesPage;
