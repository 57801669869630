import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import StoriesPage from './pages/StoriesPage/StoriesPage';
import LandingPage from './pages/LandingPage/LandingPage';
import ContactPage from './pages/ContactPage/ContactPage';
import ProgramPage from './pages/ProgramPage/ProgramPage';
import ArchivPage from './pages/ArchivPage/ArchivPage';
import RegistrationForm from './pages/RegistrationForm/RegistrationForm';
import Supporters from './pages/Supporters/Supporters';

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path="/onas" element={ <AboutUsPage />}/>
          <Route path="/pribehy/:preselectedStory" element={<StoriesPage />}/>
          <Route path="/pribehy" element={<StoriesPage />}/>
          <Route path="/kontakt" element={<ContactPage />}/>
          <Route path="/program/:preselectedId" element={<ProgramPage />}/>
          <Route path="/program" element={<ProgramPage />}/>
          <Route path="/archiv" element={<ArchivPage />}/>
      <Route path="/registrace/:id" element={<RegistrationForm />}/>
     <Route path="/registrace" element={<RegistrationForm />}/>
       <Route path="/podpora" element={<Supporters />}/>
      <Route path="/" element={<LandingPage />}/>
     </Routes>
    </BrowserRouter>
  );
}

export default App;
