import React, { useEffect } from 'react';
import EventBig from '../EventBig/EventBig';
import './programMain.scss';
import { prepareProgramdata } from '../../helpers/prepareProgramdata';

function ProgramMain({
  eventOpened,
  preselectedId,
  program,
  setEventOpened,
  slice,
  wideScreen,
}) {
  useEffect(() => {
    if (!program.data.length) return;
    setEventOpened((eventOpened) => ({
      ...eventOpened,
      id: preselectedId
        ? preselectedId
        : prepareProgramdata(program.data).slice(0, `${slice}`)[0]?.id,
      color: '#8a407f',
      btnColor: 'pinkBtn',
    }));
  }, [program, preselectedId, setEventOpened, slice]);

  return (
    <section className="program_section">
      <h2 className="programH2">Program</h2>
      <div className="events_all" id="events_all">
        {program?.loadComplete &&
          prepareProgramdata(program.data)
            ?.slice(0, `${slice}`)
            ?.map((event, i) => (
              <EventBig
                key={event.id}
                {...{ event, eventOpened, setEventOpened, wideScreen }}
                btnColor={'pinkBtn'}
                dateOrder={'-1'}
                dateAlign={'left'}
                eventAlignment={'flex-start'}
                color={'#8a407f'}
              />
            ))}
      </div>
    </section>
  );
}

export default ProgramMain;
