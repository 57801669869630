import React, { useState } from 'react';
import Gallery from '../Gallery/Gallery';
import PhotoDetails from '../Gallery/PhotoDetail/PhotoDetails';

function ArchivBigOpened({
  archivGallery,
  archivDate,
  archivSpeakerImage,
  speakerInfo,
  archivColor,
  archivTitle,
  speaker,
  archivOpened,
  archivText,
  id,
}) {
  const [selectedImg, setSelectedImg] = useState(null);

  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <>
      <div
        id={id}
        className={
          id === parseInt(archivOpened.activeArchiv)
            ? 'archivOpened'
            : 'archivClosed'
        }
      >
        <div
          className="archivOpened_top"
          style={{ backgroundColor: `${archivColor}` }}
        >
          {speaker}
        </div>
        <div className="archivOpened_content">
          <div>
            <h5 className="archivTitle">{archivTitle}</h5>
            <h6>
              Datum konání akce:{' '}
              {new Date(archivDate).toLocaleString('cs', {
                timeZone: 'UTC',
                dateStyle: 'medium',
                timeStyle: 'short',
              })}
            </h6>
            <div className="archivSpeakerInfo">
              <div className="eventMobileSpeakerImage">
                <img src={archivSpeakerImage} alt="" />
              </div>
              <div>
                <h6>Informace o přednášejícím:</h6>
                <p>{speakerInfo}</p>
              </div>
            </div>
            <div dangerouslySetInnerHTML={createMarkup(archivText)}></div>
          </div>
          <Gallery
            archivGallery={archivGallery}
            selectedImg={selectedImg}
            setSelectedImg={setSelectedImg}
          />
          {(!!selectedImg || selectedImg === 0) && (
            <PhotoDetails
              selectedImg={selectedImg}
              setSelectedImg={setSelectedImg}
              archivGallery={archivGallery}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ArchivBigOpened;
