import '@brainhubeu/react-carousel/lib/style.css';
import './storySmall.scss';

import Carousel, { Dots, arrowsPlugin } from '@brainhubeu/react-carousel';
import jitka from '../../images/jitka_resized.webp';
import bara from '../../images/bara_resized.webp';
import olga from '../../images/olga_resized.webp';
import React, { useState, useEffect } from 'react';
import left from '../../images/left.svg';
import right from '../../images/right.svg';
import leftDis from '../../images/left_disabled.svg';
import rightDis from '../../images/right_disabled.svg';
import SubPageButton from '../SubPageButton/SubPageButton';
import stories from '../../content/stories.json';
import text from '../../content/content.json';
import { useNavigate } from 'react-router';

const photos = {
  jitka: { src: jitka },
  bara: { src: bara },
  olga: { src: olga },
};

const StorySmall = () => {
  let navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    setSlides([
      ...Object.keys(stories).map((story, i) => {
        return (
          <div
            className="oneStory"
            key={i}
            onClick={() => navigate(`/pribehy/${stories[story].key}`)}
          >
            {' '}
            <h5>{stories[story].name}</h5>
            <h6>{stories[story].title}</h6>
            <div className="oneStory_bottom">
              <div
                className="oneStory_img"
                style={{
                  backgroundImage: `url(${photos[stories[story].key].src})`,
                }}
              />
              <div className="oneStory_right">
                <p>{stories[story].perex}</p>
              </div>
            </div>
          </div>
        );
      }),
    ]);
  }, [navigate]);

  const handleOnchange = (value) => {
    setValue(value);
  };

  return (
    <section id="storiesSmall" className="storiesSmall_section section">
      <SubPageButton pushTo="pribehy" title={text.stories.title} />
      <Carousel
        value={value}
        slides={slides}
        onChange={handleOnchange}
        plugins={[
          'infinite',
          {
            resolve: arrowsPlugin,
            options: {
              arrowLeft: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={left}
                    alt="šipka doleva"
                  />
                </button>
              ),
              arrowLeftDisabled: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={leftDis}
                    alt="šedá šipka doleva"
                  />{' '}
                </button>
              ),
              arrowRight: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={right}
                    alt="šipka doprava"
                  />{' '}
                </button>
              ),
              arrowRightDisabled: (
                <button
                  style={{ border: 'none', padding: '1px', width: '30px' }}
                >
                  <img
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                    src={rightDis}
                    alt="šedá šipka doprava"
                  />{' '}
                </button>
              ),
              addArrowClickHandler: true,
            },
          },
        ]}
      />
      <Dots value={value} onChange={handleOnchange} number={slides.length} />
    </section>
  );
};

export default StorySmall;
