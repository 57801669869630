import React from 'react';
import { useDkck } from '../../context/Context';
import Event from '../Event/Event';
import SubPageButton from '../SubPageButton/SubPageButton';
import './programLp.scss';
import { prepareProgramdata } from '../../helpers/prepareProgramdata';

function ProgramLp(props) {
  const { program } = useDkck();

  return (
    <section className="program_section program_sectionLp">
      <h2>Nejbližší akce</h2>
      <div className="events_all">
        {program?.loadComplete &&
          prepareProgramdata(program.data)
            ?.slice(0, `${props.slice}`)
            ?.map((event, i) => (
              <Event
                key={i}
                {...{ event }}
                btnColor={'pinkBtn'}
                dateOrder={'-1'}
                dateAlign={'left'}
                eventAlignment={'flex-start'}
                color={'#8a407f'}
              />
            ))}
      </div>
      <SubPageButton pushTo="program" title="Zobrazit všechny akce" />
    </section>
  );
}

export default ProgramLp;
