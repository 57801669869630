import React from 'react';
import { useDkck } from '../../../context/Context';
import { useParams } from 'react-router';

function HiddenInputs() {
  const { program } = useDkck();
  const { id } = useParams();

  return (
    <>
      <input
        type="hidden"
        id="eventName"
        name="custId"
        value={
          program?.loadComplete &&
          program.data.find((event) => event.id === parseInt(id)).eventName
        }
      />
      <input
        type="hidden"
        id="eventDate"
        name="eventDate"
        value={
          program?.loadComplete &&
          new Date(
            program.data.find((event) => event.id === parseInt(id)).time,
          ).toLocaleString('cs', {
            timeZone: "Europe/Prague",
            dateStyle: 'medium',
            timeStyle: 'short',
          })
        }
      />
      <input
        type="hidden"
        id="eventLocation"
        name="eventLocation"
        value={
          program?.loadComplete &&
          program.data.find((event) => event.id === parseInt(id)).location[0]
        }
      />
      <input
        type="hidden"
        id="eventMap"
        name="eventMap"
        value={
          program?.loadComplete &&
          program.data.find((event) => event.id === parseInt(id)).location[1]
        }
      />
    </>
  );
}

export default HiddenInputs;
