import React from 'react';
import { NavLink } from 'react-router-dom';
import './menuItem.scss';

function MenuItem(props) {
  return (
    <>
      <NavLink activeClassName="active"
          className="menuItem"
          to={`/${props.to}`}
          onClick={props.onClick}>
        <li
        >
          {props.itemName}
        </li>
      </NavLink>
    </>
  );
}

export default MenuItem;
