import React from 'react';
import text from '../../content/content.json';
import './aboutUs.scss';

function AboutUs(props) {
  const title = typeof props.title !== 'undefined' ? props.title : 'visible';

  return (
    <section className="aboutUs_section section">
      {title === 'visible' ? <h2>O nás</h2> : null}{' '}
      <div>
        <h3>{text.landingPage.aboutUs.title1}</h3>
        <p>{text.landingPage.aboutUs.text1p1}</p>
        <p>{text.landingPage.aboutUs.text1p2}</p>
        <p>{text.landingPage.aboutUs.text1p3}</p>
      </div>
      <div>
        <h3>{text.landingPage.aboutUs.title2}</h3>
        <ul>
          <li>{text.landingPage.aboutUs.text2p1}</li>
          <li>{text.landingPage.aboutUs.text2p2}</li>
          <li>{text.landingPage.aboutUs.text2p3}</li>
        </ul>
      </div>
    </section>
  );
}

export default AboutUs;
