import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDkck } from '../../context/Context';
import SendButton from '../Button/sendButton/SendButton';
import text from '../../content/content.json';
import arrowLeft from '../../images/arrowLeft.png';
import gdpr from '../../images/Informacni_povinnost_spravce_osobnich_udaju.pdf';
import './form.scss';
import apiKey from '../../emailJs/emailJsKey';
import emailjs from 'emailjs-com';
import Dialog from '@material-ui/core/Dialog';
import cross from '../../images/cross.svg';
import { useParams } from 'react-router';
import HiddenInputs from './HiddenInputs/HiddenInputs';

export default function Form() {
  const { id } = useParams();
  const { program } = useDkck();
  let navigate = useNavigate();
  const [fullCapacityEmail, setFullCapacityEmail] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        apiKey.SERVICE_ID,
        fullCapacityEmail ? apiKey.TEMPLATE_ID_STOPREG : apiKey.TEMPLATE_ID_REG,
        e.target,
        apiKey.USER_ID,
      )
      .then(
        () => {
          handleClickOpen();
        },
        (error) => {
          alert('Něco se pokazilo, zkuste to znovu.', error.text);
        },
      );
    document.getElementById('registrationForm').reset();
  };
  console.log(program.data);

  useEffect(() => {
    program?.loadComplete &&
      setFullCapacityEmail(
        program.data.find((event) => event.id === parseInt(id)).fullCapacity,
      );
  }, [program?.data, program?.loadComplete, id, setFullCapacityEmail]);

  return (
    <>
      <form id="registrationForm" className="form" onSubmit={handleSubmit}>
        <div className="registrationTop" onClick={() => navigate('/program')}>
          <img className="arrow" src={arrowLeft} alt="" />
          <h2>
            Registrace na akci:{' '}
            {program?.loadComplete &&
              program.data.find((event) => event.id === parseInt(id)).eventName}
          </h2>
        </div>
        <HiddenInputs />
        <div className="form_main">
          <label className="label" htmlFor="name">
            Jméno a příjmení: <br />
            <input required type="text" id="name" name="name" />
          </label>
          <label className="label" htmlFor="address">
            Adresa (ulice, č.p., PSČ, obec): <br />{' '}
            <input required type="text" id="address" name="address" />
          </label>
          <div className="form_contactDetails">
            <label className="label" htmlFor="email">
              Email: <br />{' '}
              <input required type="email" id="email" name="email" />
            </label>

            <label className="label" htmlFor="phoneNumber">
              Telefonní číslo: <br />{' '}
              <input type="text" required id="phoneNumber" name="phoneNumber" />
            </label>
            <br />
          </div>{' '}
          <br />{' '}
          {/* {program?.loadComplete &&
          program.data
            .filter((event) => event.id !== parseInt(id))
            .filter((item) => item.time && new Date(item.time) > new Date())
            .length ? (
            <p className="label">Registrujte se také na:</p>
          ) : null}
          <div className="form_events">
            {program?.loadComplete &&
              program.data
                .filter((event) => event.id !== parseInt(id))
                .filter((item) => item.time && new Date(item.time) > new Date())
                .sort((a, b) => {
                  const dateASplit = a.time.split(/\.\D|[-]|[T]/);
                  const dateA = new Date(
                    dateASplit[2],
                    dateASplit[1] - 1,
                    dateASplit[0],
                  );
                  const dateBSplit = b.time.split(/\.\D|[-]|[T]/);
                  const dateB = new Date(
                    dateBSplit[2],
                    dateBSplit[1] - 1,
                    dateBSplit[0],
                  );
                  return dateA - dateB;
                })
                .map((event, i) => (
                  <>
                    <div className="checkbox">
                      <span className="checkbox__input">
                        <input
                          id={i}
                          type="checkbox"
                          name="event"
                          value={event.eventName}
                        />
                        <span className="checkbox__control">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="4"
                              d="M1.73 12.91l6.37 6.37L22.79 4.59"
                            />
                          </svg>
                        </span>
                      </span>
                      <label id={i} htmlFor={i} className="radio__label">
                        {event.eventName}:{' '}
                        {new Date(event.time).toLocaleString('cs', {
                          timeZone: "Europe/Prague",
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        })}
                      </label>
                    </div>
                  </>
                ))}
          </div> */}
          <div style={{ width: '100%' }}>
            <p className="label">Platba: </p>
            <div className="form_payment">
              {program?.loadComplete &&
              program.data.find((event) => event.id === parseInt(id))
                .price[0] === true ? (
                <>
                  <label className="radioLabel" htmlFor="bankTransfer">
                    <input
                      className="radioInput"
                      type="radio"
                      name="payment"
                      value="platba_prevodem"
                      id="bankTransfer"
                    />{' '}
                    <span className="checkmarkRadio"></span>
                    Platba předem - převodem na konto (cena{' '}
                    {program?.loadComplete &&
                      program.data.find((event) => event.id === parseInt(id))
                        .price[1]}
                    )
                  </label>
                  <label className="radioLabel" htmlFor="cash">
                    {' '}
                    <input
                      className="radioInput"
                      type="radio"
                      name="payment"
                      value="hotovost"
                      id="cash"
                    />{' '}
                    <span className="checkmarkRadio"></span>
                    Platba na místě - v hotovosti (cena{' '}
                    {program?.loadComplete &&
                      program.data.find((event) => event.id === parseInt(id))
                        .price[2]}
                    )
                  </label>
                </>
              ) : (
                <>
                  <label className="radioLabel" htmlFor="cash">
                    {' '}
                    <input
                      disabled
                      className="radioInput"
                      type="radio"
                      name="payment"
                      value="hotovost"
                      id="cash"
                    />{' '}
                    <span className="checkmarkRadio"></span>
                    Vstupné dobrovolné na místě
                  </label>
                </>
              )}
            </div>
          </div>
          <br />
          <a className="form_gdpr" href={gdpr}>
            <p>{text.contact.GDPR}</p>
          </a>
        </div>

        <SendButton color="pink" onClick={handleClickOpen} />
        <div className="dialog">
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={dialogOpen}
            style={{ backgroundColor: 'rgba(32, 0, 80, 0.5)' }}
          >
            <div className="dialog_header" onClose={handleClose}>
              <h2 className="dialog_title">
                Vaše registrace byla odeslána, brzy se Vám ozveme.
              </h2>
              <img
                className="dialog_closeIcon"
                src={cross}
                alt="cross"
                onClick={handleClose}
              />
            </div>
            <div className="dialog_divider">
              <h3>Údaje k platbě převodem</h3>
              <p className="dialog_text">
                Číslo účtu Dámský klub Český Krumlov, z.s.:
                <strong> 298296050/0300</strong>
                <br />
                Pro zahraniční platby:{' '}
                <strong> IBAN CZ21 0300 0000 0002 9829 6050</strong>
                <br />
                Variabilní symbol: datum akce a jméno v podobě:
                <strong> xx.yy.zzzz_jméno_příjmení</strong> <br />
                Částka:{' '}
                <strong>
                  {' '}
                  {program?.loadComplete &&
                    program.data.find((event) => event.id === parseInt(id))
                      .price[1]}
                </strong>
              </p>
            </div>
          </Dialog>
        </div>
      </form>
    </>
  );
}
