export const prepareProgramdata = (programData) => {
  console.log("programData", programData)
  const programDataWithTime = programData
      .filter((item) => item.time && new Date(item.time) > new Date())
      .sort((a, b) => {
      const dateASplit = a.time.split(/\.\D|[-]|[T]/);
      const dateA = new Date(dateASplit[0], dateASplit[1] - 1, dateASplit[2])
        console.log("dateA", dateASplit, dateA)
      const dateBSplit = b.time.split(/\.\D|[-]|[T]/);
      const dateB = new Date(dateBSplit[0], dateBSplit[1] - 1, dateBSplit[2])
      return dateA - dateB;
    })
  const programDataWithoutTime = programData.filter((item) => !item.time)

  return programDataWithTime.concat(programDataWithoutTime)
}
