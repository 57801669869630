import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import AboutUs from '../../components/AboutUs/AboutUs';
import StorySmall from '../../components/StorySmall/StorySmall';
import Statement from '../../components/Statement/Statement';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';

import './aboutUsPage.scss';

function AboutUsPage() {
  return (
    <motion.div
      className="aboutUsPage page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
    >
      <Helmet>
        <title>O Dámském klubu Český Krumlov</title>
        <meta
          name="description"
          content="Sdružujeme ženy z okolí Českého Krumlova. Přečtěte si příběhy našich žen."
        />
      </Helmet>
      <Header showLPLinks={true} />
      <div className="heroSection section">
        <h1>O nás</h1>
      </div>
      <main className="main_mobile">
        {' '}
        <AboutUs title="none" />
        <Statement />
        <StorySmall />
      </main>
      <main className="main_desktop">
        <div className="main_left">
          {' '}
          <AboutUs title="none" />
          <StorySmall />
        </div>
        <div className="main_right">
          <Statement />
        </div>
      </main>
      <Footer />
    </motion.div>
  );
}

export default AboutUsPage;
