import React from 'react';
import Header from '../../components/Header/Header';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import './supporters.scss';
import text from '../../content/content.json';
import luna from '../../images/luna.png';
import SupporterOne from '../../components/SupporterOne/SupporterOne';
import { Helmet } from 'react-helmet';

function Supporters() {
  return (
    <motion.div
      className="supporters_page page"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
    >
      <Helmet>
        <title>Podporovatelé Dámského klubu</title>
      </Helmet>
      <Header showLPLinks={true} />
      <div className="supportersHero">
        <h1>Děkujeme za podporu</h1>
      </div>
      <section className="supporters_section">
        <div>
          <h2>Podporují nás</h2>
          <div className="supporters_all">
            <SupporterOne
              supporter="Kino Luna Český Krumlov"
              supporterText="Děkujeme za zázemí v Klubovně Luna"
              supporterLogo={luna}
              href="http://www.luna-kino.cz/"
            />
            <SupporterOne
              supporter="Město Český Krumlov"
              supporterText="Děkujeme za podporu a spolupráci"
              supporterLogo="https://www.divadlo.ckrumlov.cz/img/9072106b.jpg"
              href="https://www.ckrumlov.cz/cz/mesto-cesky-krumlov/"
            />
            <SupporterOne
              supporter="Advokátní kancelář Zajíčková s.r.o."
              supporterText="Děkujeme za právní rady"
              href="https://www.akzajickova.cz/"
            />
            <SupporterOne
              supporter="Jindřiška Smolíková"
              supporterText="Děkujeme za hlídání účtů"
            />
            <SupporterOne
              supporter="PaedDr. Alena Švepešová"
              supporterText="Děkujeme za příspěvek na činnost"
            />
            <SupporterOne
              supporter="Ing. Zuzana Pecková"
              supporterText="Děkujeme za příspěvek na činnost"
            />
          </div>
        </div>
        <div className="supportUs">
          <h2>Jak nás můžete podpořit</h2>
          <ul>
            <li>
              Rády se s Vámi potkáme a domluvíme individuální možnosti
              spolupráce a možné podpory.
            </li>
            <li>
              Chcete se stát ambasadorem / ambasadorkou nebo být jedním z
              přednášejících? Neváhejte a kontaktujte nás.
            </li>
            <li>
              Jsme nezisková organizace a děkujeme za každý finanční příspěvek
              na činnost na náš účet Dámský klub Český Krumlov, z.s.
              298296050/0300. Pro zahraničí IBAN CZ21 0300 0000 0002 9829 6050).{' '}
            </li>
          </ul>
        </div>{' '}
        <h2>Kontaktujte nás</h2>
        <div className="contactForm">
          <div className="contactForm_wrapper">
            Emailem:{' '}
            <a href="mailto:info@damskyklubck.cz">{text.contact.email}</a>
          </div>
          <div className="contactForm_wrapper">
            Telefonicky:
            <a href="tel:420-724-058-343"> {text.contact.phone}</a>
          </div>
        </div>
      </section>
      <Footer />
    </motion.div>
  );
}

export default Supporters;
