import React from 'react';

const ImageLink = ({ url, icon, children }) => {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
            {children} {icon && <span style={{ marginLeft: '8px' }}>{icon}</span>}
        </a>
    );
};

export default ImageLink;