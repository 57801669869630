import React from 'react';
import AboutUs from '../../components/AboutUs/AboutUs';
import Header from '../../components/Header/Header';
import ProgramLp from '../../components/ProgramLp/ProgramLp';
import ReferenceQuote from '../../components/ReferenceQuote/ReferenceQuote';
import { motion } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import './landingPage.scss';
/* import SubPageButton from '../../components/SubPageButton/SubPageButton';
 */

function LandingPage() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, ease: 'easeIn' } }}
      exit={{ opacity: 0, transition: { duration: 0.3, ease: 'easeOut' } }}
      className="landingPage page"
    >
      <Header showLPLinks={true} />
      <div className="heroSection section">
        <h1>Dámský klub</h1>
        <h2>Český Krumlov</h2>
      </div>
      <main className="main_mobile">
        <AboutUs />
        <ProgramLp slice="4" />
        <ReferenceQuote />
      </main>
      <main className="main_desktop">
        <div className="main_left">
          <AboutUs />
          <ReferenceQuote />
        </div>
        <div className="main_right">
          <ProgramLp slice="3" />
          {/*  <SubPageButton pushTo="program" title="Všechny akce"/> */}
        </div>
      </main>
      <Footer />
    </motion.div>
  );
}

export default LandingPage;
