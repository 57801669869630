import React, { useState } from 'react';
import MenuItem from './MenuItem/MenuItem';
import hamburger from '../../images/hamburger.svg';
import './navbar.scss';

function Navbar(props) {
  const [menuOpened, setMenuOpened] = useState(false);

  const toggleMenu = () => {
    setMenuOpened(menuOpened === false ? true : false);
  };

  return (
    <>
      <nav className="navbar">
        <div className="fa fa-bars" aria-hidden="true" onClick={toggleMenu}>
          <img src={hamburger} alt="ikona mobilniho menu" />
        </div>

        <ul className={`collapsed ${menuOpened ? 'is-expanded' : ''}`}>
          <MenuItem itemName="O nás" onClick={toggleMenu} to="onas"></MenuItem>
          <MenuItem
            itemName="Program"
            onClick={toggleMenu}
            to="program"
          ></MenuItem>
          <MenuItem
            itemName="Podporují nás"
            onClick={toggleMenu}
            to="podpora"
          />
          <MenuItem
            itemName="Kontakty"
            onClick={toggleMenu}
            to="kontakt"
          ></MenuItem>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;
