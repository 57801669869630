import './storyBig.scss';
import React from 'react';
import arrowDown from '../../images/arrowDown.svg';

function StoryBig({
  storyColor,
  name,
  alignment,
  id,
  title,
  storyOpened,
  toggleActive,
}) {
  return (
    <>
      <div className="story">
        <div
          className="storyName_div"
          onClick={() => toggleActive(id)}
          style={{
            backgroundColor: `${storyColor}`,
            alignSelf: `${alignment}`,
          }}
        >
          <div className="storyName_div2">
            <h4>{name}</h4>
            <img
              src={arrowDown}
              alt="arrow icon"
              className={
                id === storyOpened.activeStory ? 'arrowUp' : 'arrowDown'
              }
            />
          </div>
          <h5>{title}</h5>
        </div>
      </div>
    </>
  );
}

export default StoryBig;
