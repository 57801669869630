import './eventBig.scss';
import React from 'react';
import Button from '../Button/Button';
import arrowDown from '../../images/arrowDown.svg';
import location from '../../images/location.svg';

function EventBig({
  btnColor,
  color,
  dateAlign,
  dateOrder,
  event,
  eventAlignment,
  eventOpened,
  setEventOpened,
  wideScreen,
}) {
  const toggleActiveMobile = (id, color, btnColor) => {
    if (eventOpened.id === id && !wideScreen) {
      setEventOpened({
        ...eventOpened,
        id: undefined,
        color: color,
        btnColor: btnColor,
      });
    } else {
      setEventOpened({
        ...eventOpened,
        id: id,
        color: color,
        btnColor: btnColor,
      });
    }
  };

  function createMarkup(text) {
    return { __html: text };
  }

  return (
    <div className="eventMobileBig">
      <div
        className="eventMobileClick"
        style={{ alignSelf: `${eventAlignment}` }}
      >
        <div
          onClick={() =>
            toggleActiveMobile(event.id.toString(), color, btnColor)
          }
          className="eventMobileClick_speaker"
          style={{ backgroundColor: `${color}` }}
        >
          <h4>{event.speaker}</h4>
          <img
            src={arrowDown}
            alt="arrow icon"
            className={
              parseInt(eventOpened.id) === event.id && !wideScreen
                ? 'arrowUp'
                : 'arrowDown'
            }
          />
        </div>
        <div className="eventMobileClick_details">
          <div
            className="eventMobileClick_info"
            style={{
              order: `${dateOrder}`,
              textAlign: `${dateAlign}`,
            }}
          >
            <p className="eventMobileClick_title">{event.eventName}</p>
            <p className="eventMobileClick_date">
              {event.time
                ? new Date(event.time).toLocaleString('cs', {
                    timeZone: "Europe/Prague",
                    dateStyle: 'medium',
                    timeStyle: 'short',
                  })
                : 'Datum bude upřesněno'}
            </p>
            {event.location && (
              <a
                className="event_location"
                href={event.location[1]}
                target="_blank"
                rel="noreferrer"
              >
                <img src={location} alt="ikona špendlíku na mapě" />
                <p>{event.location[0]}</p>
              </a>
            )}
          </div>
          <div className="event_buttons">
            <Button color={btnColor} id={event.id} />
            {event.fullCapacity === true ? (
              <span className="soldOut">
                Vyprodáno
                <br />
                <span className="soldOut_waitingList">(pořadník)</span>
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div
        id={event.id}
        className={
          parseInt(eventOpened.id) === event.id && !wideScreen
            ? 'eventOpened'
            : 'eventClosed'
        }
      >
        <div
          className="eventMobileOpened_top"
          style={{ backgroundColor: `${color}` }}
        >
          {event.speaker}
        </div>
        <div className="eventMobileOpened_content">
          <h5 className="eventMobileTitle">{event.eventName}</h5>
          <h4 className="eventMobile_dateTime">
            {new Date(event.time).toLocaleString('cs', {
              timeZone: "Europe/Prague",
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
          </h4>

          <div className="eventMobile_moreInfo">
            <div>
              <h5>Detaily akce</h5>
              <p dangerouslySetInnerHTML={createMarkup(event.desc)} />
            </div>
            <div>
              <h5>S kým se potkáte</h5>{' '}
              <div className="eventMobileSpeakerImage">
                <img src={event.speakerImage} alt="" />
              </div>
              <p>{event.speakerInfo}</p>
            </div>
          </div>
          <Button color={event.btnColor} id={event.id} />
        </div>
      </div>
    </div>
  );
}

export default EventBig;
