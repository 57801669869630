import React from 'react';
import { useNavigate } from 'react-router-dom';

import './button.scss';

function Button(props) {
  let navigate = useNavigate();

  const color = typeof props.color !== 'undefined' ? props.color : 'purple';

  const handleClick = () => {
    // tohle je tady kvůli požadavku na RYOR akci . Může se odstranit po 24.11.2022
    if (props.id === 8) {
      window.open('https://www.jhk.cz/planovane-akce/navsteva-kosmeticke-firmy-ryor','_newtab')
    } else {
      navigate(`/registrace/${props.id}`)
    }
  }

  return (
    <button
      className={`registrationBtn ${color}`}
      onClick={handleClick}
    >
      Registrovat
    </button>
  );
}

export default Button;
